import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const AboutUsWrpStyled = styled.div`
  ${tw`pb-16 md:pb-24 lg:pb-44`}

  .aboutus-inner-wrp {
    ${tw`grid grid-cols-1 lg:grid-cols-3 lg:gap-6`}

    .about-content-item-wrp {
      ${tw`col-span-2 grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-14 items-start`}

      @media (min-width: ${fullConfig.theme.screens.lg}) {
        padding-left: 75px;
      }

      .about-content-item {
        ${tw`text-center lg:text-left flex flex-col items-center lg:items-start mb-16 md:mb-0`}

        .image-wrp {
          ${tw`w-32 lg:w-36 h-32 lg:h-36 rounded-2xl flex justify-center items-center shadow-2xl mb-8`}

          &.green-box {
            background: transparent linear-gradient(225deg, #70b32f 0%, #85e02d 100%) 0% 0%
              no-repeat padding-box;
          }

          &.orange-box {
            background: transparent linear-gradient(225deg, #f08b02 0%, #fc9201 100%) 0% 0%
              no-repeat padding-box;
          }

          &.primary-box {
            background: transparent linear-gradient(225deg, #0477ba 0%, #0085d3 100%) 0% 0%
              no-repeat padding-box;
          }
        }

        h4 {
          ${tw`mb-5 uppercase`}
        }

        .content {
          ${tw`text-primary-dimGray leading-7`}
        }
      }
    }
  }
`;

export const DescriptionSectionStyled = styled.div`
  ${tw`text-center lg:text-left pb-16 md:pb-24 lg:pb-0 mx-auto lg:m-0`}
  max-width: 544px;

  .image-wrp {
    ${tw`mb-12 flex justify-center lg:justify-start`}

    svg {
      fill: #000d3f;
    }
  }

  h2 {
    ${tw`mb-5 text-primary-dark`}
  }

  .tagline {
    ${tw`font-bold mb-5 leading-7`}
  }

  .content {
    ${tw`mb-7 leading-7`}
  }

  .contact-us {
    max-width: 320px;
    margin: 0 auto;

    @media (min-width: ${fullConfig.theme.screens.md}) {
      max-width: 180px;
    }

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      margin: 0;
    }
  }
`;
