import React from 'react';
import PropTypes from 'prop-types';

import Link from '../Link';

import { SolutionWrp } from './solutions.styled';

export default function Solutions(props) {
  const {
    mainContentIcon,
    mainContentTitle,
    mainContentContents,
    mainContentButtonLink,

    gridTiedIcon,
    gridTiedTitle,
    gridTiedContents,
    gridTiedButtonLink,

    offGridIcon,
    offGridTitle,
    offGridContents,
    offGridButtonLink,
  } = props;
  return (
    <>
      <SolutionWrp className="solution-section ves-section">
        <div className="solutions-outer-wrp ves-container">
          <div className="solutions-inner-wrp">
            <div className="solution-content-wrp">
              {/* <SolarEnergy className="solar-energy" /> */}
              <img src={mainContentIcon} alt="solar-energy-icon" className="solar-energy" />
              <h2 className="h2">{mainContentTitle}</h2>
              {/* <div className="tagline">
                Smart partnerships bring smart solar energy solutions to Fiji.
              </div>
              <div className="content">
                Our innovative technologies make solar possible for households, businesses, property
                owners and entire communities.
              </div> */}

              {/* eslint-disable react/no-danger */}
              <div className="content" dangerouslySetInnerHTML={{ __html: mainContentContents }} />
              {mainContentButtonLink && (
                <Link to={mainContentButtonLink} className="btn contact-us">
                  Contact Us
                </Link>
              )}
              {/* <Button text="ss" /> */}
            </div>
            <div className="solution-type-cards-wrp">
              <div className="solution-card-item bg-success">
                <div className="content-wrp">
                  {/* <GridTied className="card-item-image" /> */}
                  <img src={gridTiedIcon} alt="icon" className="card-item-image" />
                  <h3 className="h3">{gridTiedTitle}</h3>
                  <div className="content p-sm">
                    {/* <p>Lower your utility bills by generating your own electricity.</p> */}
                    <p dangerouslySetInnerHTML={{ __html: gridTiedContents }} />
                  </div>
                </div>
                <div className="button-wrp">
                  {/* <a href="#123" className="learn-more">
                    Learn How
                  </a> */}
                  {gridTiedButtonLink && (
                    <Link to={gridTiedButtonLink} className="learn-more">
                      Read More
                    </Link>
                  )}
                </div>
              </div>
              <div className="solution-card-item bg-primary-dark">
                <div className="content-wrp">
                  {/* <OffGrid className="card-item-image" /> */}
                  <img src={offGridIcon} alt="icon" className="card-item-image" />
                  <h3 className="h3">{offGridTitle}</h3>
                  <div className="content p-sm">
                    {/* <p>Lower your utility bills by generating your own electricity.</p> */}
                    <p dangerouslySetInnerHTML={{ __html: offGridContents }} />
                  </div>
                </div>

                <div className="button-wrp">
                  {/* <a href="#123" className="learn-more">
                    Learn How
                  </a> */}
                  {offGridButtonLink && (
                    <Link to={offGridButtonLink} className="learn-more">
                      Read More
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SolutionWrp>
    </>
  );
}

Solutions.propTypes = {
  mainContentIcon: PropTypes.string.isRequired,
  mainContentTitle: PropTypes.string.isRequired,
  mainContentContents: PropTypes.string.isRequired,
  mainContentButtonLink: PropTypes.string,

  gridTiedIcon: PropTypes.string.isRequired,
  gridTiedTitle: PropTypes.string.isRequired,
  gridTiedContents: PropTypes.string.isRequired,
  gridTiedButtonLink: PropTypes.string,

  offGridIcon: PropTypes.string.isRequired,
  offGridTitle: PropTypes.string.isRequired,
  offGridContents: PropTypes.string.isRequired,
  offGridButtonLink: PropTypes.string,
};
