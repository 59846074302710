import React from 'react';
import 'flickity-fade';
import 'flickity-as-nav-for';
import Flickity from 'react-flickity-component';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import Link from '../Link';

import {
  CaseStudiesStyled,
  SectionHeaderStyled,
  SliderStyled,
  SlideStyled,
  SliderWrapperStyled,
  SliderNav,
} from './case-studies.styled';

const flickityOptions = {
  initialIndex: 0,
  autoplay: true,
  pauseAutoPlayOnHover: false,
  fade: true,
  cellAlign: 'center',
  prevNextButtons: false,
};

const flickityNavOptions = {
  initialIndex: 0,
  autoplay: true,
  pauseAutoPlayOnHover: false,
  pageDots: false,
  asNavFor: '.main-carousel',
  contain: true,
  cellAlign: 'center',
  prevNextButtons: false,
};

export default function CaseStudies(props) {
  const { sectionTitle, sectionDescription, caseStudiesFeatured } = props;

  const slide = (
    title,
    alternativeTitle,
    content,
    uri,
    companyLogo,
    companyLogoInSVG,
    featuredImage,
  ) => {
    return (
      <SlideStyled>
        <div className="slider-image">
          <GatsbyImage image={featuredImage} alt={title} />
        </div>
        <div className="slider-content">
          <div className="flex flex-col md:flex-row md:items-center">
            {companyLogo ? <GatsbyImage image={companyLogo} alt={title} /> : ''}

            <h4 className="h4">{alternativeTitle || title}</h4>
          </div>
          <p className="p">{content}</p>

          <Link to={uri} className="more">
            Case study
          </Link>
        </div>
      </SlideStyled>
    );
  };

  return (
    <CaseStudiesStyled className="casestudy-section ves-section">
      <div className="ves-container">
        <SectionHeaderStyled>
          {sectionTitle && <h2 className="h2">{sectionTitle}</h2>}
          {sectionDescription && <h4 className="h4 tagline">{sectionDescription}</h4>}
        </SectionHeaderStyled>

        <SliderWrapperStyled>
          <SliderNav>
            <Flickity
              className="carousel-nav" // default ''
              elementType="div" // default 'div'
              options={flickityNavOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              static // default false
            >
              {caseStudiesFeatured.map((item) => (
                <div className="btn" key={item.caseStudies.id}>
                  {item.caseStudies.title}
                </div>
              ))}
            </Flickity>
          </SliderNav>
          <SliderStyled>
            <div className="slider-container flex">
              <Flickity
                className="main-carousel" // default ''
                elementType="div" // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
                // flickityRef={(c) => {
                //  flkty = c;
                // }}
              >
                {caseStudiesFeatured.map((item) => (
                  <React.Fragment key={item.caseStudies?.id}>
                    {slide(
                      item.caseStudies?.title,
                      item.caseStudies.acfCaseStudies.alternativeTitle,
                      item.caseStudies.acfCaseStudies.briefDescription,
                      item.caseStudies.uri,
                      getImage(item.caseStudies?.acfCaseStudies?.companyLogo?.localFile),
                      item.caseStudies?.acfCaseStudies?.companyLogo?.sourceUrl,
                      getImage(item.caseStudies?.featuredImage?.node.localFile),
                    )}
                  </React.Fragment>
                ))}
              </Flickity>
            </div>
          </SliderStyled>
        </SliderWrapperStyled>
      </div>
    </CaseStudiesStyled>
  );
}

CaseStudies.propTypes = {
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  caseStudiesFeatured: PropTypes.arrayOf(PropTypes.any),
};
