import tw, { styled } from 'twin.macro';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const CaseStudiesStyled = styled.section`
  ${tw`block relative`};
`;

export const SectionHeaderStyled = styled.div`
  ${tw`flex flex-col relative items-center text-center`};
  h2 {
    ${tw`mb-3 text-primary-dark`}
  }
  .tagline {
    ${tw`font-normal mb-5 leading-8 text-gray-dark`}

    max-width: 230px;

    @media (min-width: ${fullConfig.theme.screens.md}) {
      max-width: 315px;
    }
  }
`;

export const SliderWrapperStyled = styled.div`
  ${tw`block relative`};
`;

export const SlideStyled = styled.div`
  ${tw`flex flex-col lg:flex-row w-full`};

  .slider-image {
    ${tw`overflow-hidden mb-4 md:mb-0`}

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      width: 66.2%;
    }
  }
  .h4 {
    ${tw`mt-6 md:mt-0 text-primary-dark`}
  }
  .p {
    ${tw`text-gray-dark leading-8`}
  }
  .more {
    ${tw`leading-3 text-primary-dark tracking-widest uppercase font-bold pr-5 mt-8 relative flex items-center`}
    &::after {
      content: '';
      display: block;
      background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2711.05%27 height=%2710.77%27 viewBox=%270 0 11.05 10.77%27%3E%3Cpath id=%27Icon_awesome-arrow-right%27 data-name=%27Icon awesome-arrow-right%27 d=%27M4.7,3.369l.547-.547a.589.589,0,0,1,.836,0l4.794,4.792a.589.589,0,0,1,0,.836L6.082,13.243a.589.589,0,0,1-.836,0L4.7,12.7a.593.593,0,0,1,.01-.846L7.68,9.019H.592A.59.59,0,0,1,0,8.427V7.638a.59.59,0,0,1,.592-.592H7.68L4.708,4.215A.588.588,0,0,1,4.7,3.369Z%27 transform=%27translate%280 -2.647%29%27 fill=%27%23000d3f%27/%3E%3C/svg%3E%0A');
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      margin-left: 8px;
    }
  }
  .slider-content {
    ${tw`md:rounded-3xl md:bg-white md:shadow-lg lg:shadow-none md:px-16 md:py-20 md:mt-0 z-10 md:mx-auto lg:my-auto lg:ml-0 w-full lg:absolute lg:opacity-0`}
    ${tw`transition transition-all ease-in-out duration-500 delay-200`};
    @media (min-width: ${fullConfig.theme.screens.md}) {
      max-width: 490px;
    }
    @media (min-width: ${fullConfig.theme.screens.lg}) {
      transform: translateY(-50%);
      top: 50%;
      right: -40px;
    }

    .gatsby-image-wrapper {
      flex: 1 0 auto;
      ${tw`md:mr-12`}
    }

    img {
      object-fit: contain !important;
      max-width: 100px;
      ${tw`md:mt-0`}
    }
  }
  &.is-selected {
    .slider-content {
      ${tw`md:-mt-32 z-10 md:mx-auto lg:my-auto lg:opacity-100`}
      @media (min-width: ${fullConfig.theme.screens.lg}) {
        transform: translateY(-50%);
        top: 50%;
        right: 10px;
      }
    }
  }
`;

export const SliderStyled = styled.div`
  ${tw`block relative`};
  .main-carousel {
    ${tw`w-full `};
    .slide {
      ${tw`w-full `}
    }
  }
`;

export const SliderNav = styled.div`
  ${tw`block relative mb-6 mt-8 md:mb-12 md:mb-16 md:mt-12 md:mt-16`};
  .btn {
    ${tw`bg-transparent text-primary border-none bg-gradient-to-r from-transparent  to-transparent px-12 whitespace-nowrap`};
    &.is-selected {
      ${tw`bg-gradient-to-r from-success  to-primary text-white`};
    }
  }
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    > div {
      counter-increment: carousel-cell;
    }
  }
  /* draggable */
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .flickity-button {
    position: absolute;
  }
`;
