import React from 'react';
import PropTypes from 'prop-types';

import Link from '../Link';

import { AboutUsWrpStyled, DescriptionSectionStyled } from './aboutus.styled';

export default function AboutUs(props) {
  const { mainContentTitle, mainContentContents, buttonLink, mainContentIcon, features } = props;

  function colorClass(color) {
    switch (color) {
      case 'green':
        return 'green-box';
      case 'orange':
        return 'orange-box';
      default:
        return 'primary-box';
    }
  }

  function colorClassText(color) {
    switch (color) {
      case 'green':
        return 'text-success';
      case 'orange':
        return 'text-secondary-orange';
      default:
        return 'text-primary';
    }
  }

  return (
    <>
      <AboutUsWrpStyled className="aboutus-section">
        <div className="aboutus-outer-wrp ves-container">
          <div className="aboutus-inner-wrp">
            <DescriptionSectionStyled>
              <div className="image-wrp">
                <img src={mainContentIcon} alt="section-icon" />
              </div>
              {/* eslint-disable react/no-danger */}
              <h2 className="h2" dangerouslySetInnerHTML={{ __html: mainContentTitle }} />

              {/* <div className="tagline">
                VES is the renewable energy division of Vision Investments Limited of Fiji.
              </div>
              <div className="content">
                We pride ourselves on excellent customer service while delivering comprehensive
                energy solutions to diverse clients. Our systems are designed to generate
                electricity sustainably for years.
              </div> */}

              <div className="content" dangerouslySetInnerHTML={{ __html: mainContentContents }} />
              {buttonLink && (
                <Link to={buttonLink} className="btn contact-us">
                  Contact us
                </Link>
              )}
            </DescriptionSectionStyled>
            <div className="about-content-item-wrp">
              {features.map((item) => (
                <div className="about-content-item" key={item.title}>
                  <div className={`image-wrp ${colorClass(item.color)}`}>
                    <img src={item.icon?.localFile.publicURL} alt="icon" />
                  </div>
                  <h4 className={`h4 ${colorClassText(item.color)}`}>{item.title}</h4>
                  <div className="content">{item.description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </AboutUsWrpStyled>
    </>
  );
}

AboutUs.propTypes = {
  mainContentTitle: PropTypes.string,
  mainContentContents: PropTypes.string,
  buttonLink: PropTypes.string,
  mainContentIcon: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.any),
};
