import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import BlogCard from '../blog-card';

import { LatestBlogsStyled, SectionHeaderStyled } from './latest.blog.styled';

const filterPostsByCategory = (posts, postCategories, postId) => {
  let category;
  const itemArr = [];
  const filteredItems = [];

  postCategories.map((item) => {
    category = item.id;
    return category;
  });

  posts.map((item) =>
    item.categories.nodes.map((cat) => {
      if (cat.id === category) {
        itemArr.push(item);
      }
      return item;
    }),
  );

  // show posts other than the current Post ID
  itemArr.map((item) => {
    if (item.id !== postId) {
      filteredItems.push(item);
    }
    return item;
  });

  return filteredItems;
};

const LatestBlogJSX = ({
  data,
  sectionTitle,
  sectionDescription,
  className,
  disableContainer,
  showRelatedContent,
  postCategories,
  postId,
}) => {
  let blogPosts = data.allWpPost.nodes;

  if (showRelatedContent) {
    blogPosts = filterPostsByCategory(blogPosts, postCategories.nodes, postId);
  }

  return (
    <>
      {blogPosts?.length >= 1 && (
        <LatestBlogsStyled className={`ves-section ${className || ''}`}>
          <div className={disableContainer ? '' : 'ves-container'}>
            <SectionHeaderStyled className="heading-wrp">
              {sectionTitle && <h2 className="h2">{sectionTitle}</h2>}
              {sectionDescription && <h4 className="h4 tagline">{sectionDescription}</h4>}
            </SectionHeaderStyled>

            <div className="grid md:grid-cols-3 gap-3">
              {blogPosts.slice(0, 3).map((item) => (
                <BlogCard
                  key={item.id}
                  date={item.date}
                  title={item.title}
                  description={item.acfBlogDetail.briefDescription}
                  link={item.uri}
                  image={item.featuredImage.node.localFile}
                />
              ))}
            </div>
          </div>
        </LatestBlogsStyled>
      )}
    </>
  );
};

export default function LatestBlog(props) {
  return (
    <StaticQuery
      query={graphql`
        query BlogPosts {
          allWpPost(sort: { order: DESC, fields: date }) {
            nodes {
              id
              title
              uri
              date(formatString: "MMMM DD, YYYY")
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 375, placeholder: BLURRED, quality: 90)
                    }
                  }
                }
              }
              acfBlogDetail {
                briefDescription
              }
              categories {
                nodes {
                  id
                }
              }
            }
          }
        }
      `}
      render={(data) => <LatestBlogJSX data={data} {...props} />}
    />
  );
}

LatestBlogJSX.propTypes = {
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  postId: PropTypes.string,
  className: PropTypes.string,
  showRelatedContent: PropTypes.bool,
  disableContainer: PropTypes.bool,
  postCategories: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.any),
  }),
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.any),
    }),
  }),
};
