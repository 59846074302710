import tw, { styled } from 'twin.macro';

export const LatestBlogsStyled = styled.section`
  ${tw`block relative`};
`;

export const SectionHeaderStyled = styled.div`
  ${tw`flex flex-col relative items-center text-center`};

  h2 {
    ${tw`mb-3 text-primary-dark`}
  }

  .tagline {
    ${tw`font-normal mb-12 md:mb-14 leading-8 text-gray-dark`}
  }
`;
