import * as React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../layouts/layout';
import HomeBanner from '../components/homeBanner';
import CaseStudies from '../components/case-studies';
import LatestBlog from '../components/latest-blogs';
import Solutions from '../components/SolarSolutions';
import AboutUs from '../components/AboutUs';
import CTA from '../components/CTA';
import LatestNews from '../components/LatestNews';
import HomeContactForm from '../components/homeContactForm';
import Partners from '../components/Partners';
import MetaTags from '../components/SiteMeta';

const IndexPage = ({ data }) => {
  const { acfHomePage } = data?.page?.nodes[0];
  const { acfGlobalContent } = data?.page?.nodes[0];
  return (
    <>
      {typeof window !== 'undefined' && (
        <Layout preFooterContent={acfGlobalContent}>
          <MetaTags
            title={acfGlobalContent.metaTitle}
            description={acfGlobalContent.metaDescription}
            image={acfGlobalContent.metaOgImage?.sourceUrl}
          />
          <HomeBanner
            title={acfHomePage.bannerTitle}
            subtitle={acfHomePage.bannerSubTitle}
            buttonLink={acfHomePage.bannerButtonLink.url}
            buttonText={acfHomePage.bannerButtonText}
            banner={acfHomePage.bannerImage?.localFile}
            bannerTablet={acfHomePage.bannerImageTablet?.localFile}
            bannerMobile={acfHomePage.bannerImageMobile?.localFile}
          />

          <Solutions
            mainContentIcon={acfHomePage.solarSolutionMainContent.icon?.localFile.publicURL}
            mainContentTitle={acfHomePage.solarSolutionMainContent.title}
            mainContentContents={acfHomePage.solarSolutionMainContent.content}
            mainContentButtonLink={acfHomePage.solarSolutionMainContent.buttonLink.url}
            gridTiedIcon={acfHomePage.solarSolutionGridTiedSolutions.icon?.localFile.publicURL}
            gridTiedTitle={acfHomePage.solarSolutionGridTiedSolutions.title}
            gridTiedContents={acfHomePage.solarSolutionGridTiedSolutions.content}
            gridTiedButtonLink={acfHomePage.solarSolutionGridTiedSolutions.buttonLink.url}
            offGridIcon={acfHomePage.solarSolutionOffGridSolutions.icon?.localFile.publicURL}
            offGridTitle={acfHomePage.solarSolutionOffGridSolutions.title}
            offGridContents={acfHomePage.solarSolutionOffGridSolutions.content}
            offGridButtonLink={acfHomePage.solarSolutionOffGridSolutions.buttonLink.url}
          />

          {acfHomePage.showCtaSection && (
            <CTA
              ctaTitle={acfHomePage.ctaTitle}
              ctaContent={acfHomePage.ctaDescription}
              ctaImage={acfHomePage.ctaFeaturedImage.localFile}
              ctaButtonText={acfHomePage.ctaButtonText}
              ctaBgColor={acfHomePage.ctaBackgroundColor}
              imageRight={acfHomePage.ctaImagePosition}
              ctaDownloadLink={acfHomePage.ctaDownloadLink}
            />
          )}

          {acfHomePage.caseStudiesShow && (
            <CaseStudies
              sectionTitle={acfHomePage.caseStudiesTitle}
              sectionDescription={acfHomePage.caseStudiesDescription}
              caseStudiesFeatured={acfHomePage.caseStudiesFeatured}
            />
          )}

          <AboutUs
            mainContentIcon={acfHomePage.aboutMainContent.icon?.localFile.publicURL}
            mainContentTitle={acfHomePage.aboutMainContent.title}
            mainContentContents={acfHomePage.aboutMainContent.content}
            buttonLink={acfHomePage.aboutMainContent.buttonLink.url}
            features={acfHomePage.aboutSpecialFeatures}
          />

          <HomeContactForm
            title={acfHomePage?.contactFormTitle}
            subTitle={acfHomePage?.contactFormTagline}
            description={acfHomePage?.contactFormDescription}
            backgroundImage={acfHomePage?.contactFormFeaturedImage?.localFile.publicURL}
          />

          {acfHomePage.blogShow && (
            <LatestBlog
              sectionTitle={acfHomePage.blogTitle}
              sectionDescription={acfHomePage.blogDescription}
            />
          )}

          <Partners
            title={acfHomePage.partnersTitle}
            subTitle={acfHomePage.partnersDescription}
            logos={acfHomePage.partnersLogos}
          />

          {acfHomePage.newsShow && <LatestNews />}
        </Layout>
      )}
    </>
  );
};

export const query = graphql`
  query HomePage {
    page: allWpPage(filter: { slug: { eq: "home" } }) {
      nodes {
        acfHomePage {
          bannerSubTitle
          bannerTitle
          bannerButtonLink {
            url
          }
          bannerButtonText
          bannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2020, quality: 90, placeholder: BLURRED)
              }
            }
          }
          bannerImageTablet {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 970, quality: 90)
              }
            }
          }
          bannerImageMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 475, quality: 90)
              }
            }
          }
          solarSolutionMainContent {
            title
            content
            buttonLink {
              url
            }
            icon {
              localFile {
                publicURL
              }
            }
          }
          solarSolutionGridTiedSolutions {
            buttonLink {
              url
            }
            content
            title
            icon {
              localFile {
                publicURL
              }
            }
          }
          solarSolutionOffGridSolutions {
            buttonLink {
              url
            }
            content
            title
            icon {
              localFile {
                publicURL
              }
            }
          }
          caseStudiesTitle
          caseStudiesShow
          caseStudiesDescription
          caseStudiesFeatured {
            caseStudies {
              ... on WpCaseStudy {
                id
                title
                uri
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 90, width: 770)
                      }
                    }
                  }
                }
                acfCaseStudies {
                  briefDescription
                  alternativeTitle
                  companyLogo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 82, height: 50, quality: 90)
                      }
                    }
                    sourceUrl
                  }
                }
              }
            }
          }
          aboutSpecialFeatures {
            title
            color
            description
            icon {
              localFile {
                publicURL
              }
            }
          }
          aboutMainContent {
            title
            content
            buttonLink {
              url
            }
            icon {
              localFile {
                publicURL
              }
            }
          }
          blogShow
          blogTitle
          blogDescription
          newsShow
          ctaButtonText
          ctaDescription
          ctaDownloadLink
          ctaBackgroundColor
          ctaTitle
          ctaImagePosition
          ctaFeaturedImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, width: 375)
              }
            }
          }
          showCtaSection
          partnersTitle
          partnersDescription
          partnersLogos {
            logo {
              localFile {
                publicURL
                id
              }
            }
          }
          contactFormTitle
          contactFormTagline
          contactFormDescription
          contactFormFeaturedImage {
            localFile {
              publicURL
            }
          }
        }
        acfGlobalContent {
          prefooterButtonLink {
            url
          }
          prefooterButtonText
          prefooterTitle
          metaTitle
          metaDescription
          metaOgImage {
            sourceUrl
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default IndexPage;
