import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
// import resolveConfig from 'tailwindcss/resolveConfig';

// import tailwindConfig from '../../../tailwind.config';

// const fullConfig = resolveConfig(tailwindConfig);

export const LatestNewsWrpStyled = styled.section`
  .latest-news-inner-wrp {
    h2 {
      ${tw`mb-8 lg:mb-12 text-primary-dark`}
    }

    .latest-news-item-wrp {
      ${tw`grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6`}

      .latest-news-item {
        .news-item-link {
          ${tw`grid grid-cols-1 lg:grid-cols-3 lg:gap-6`}

          .image-wrp {
            ${tw`mb-5 lg:mb-0`}

            img {
              ${tw`w-full md:w-auto`}
            }
          }

          .content-wrp {
            ${tw`lg:col-span-2 lg:gap-4`}

            .date {
              ${tw`text-primary-dimGray mb-2 inline-block`}
            }

            h4 {
              ${tw`uppercase text-primary-dark mb-5`}
            }

            .content {
              ${tw`mb-5 lg:mb-2`}
            }

            .more-news {
              ${tw`font-bold text-primary-dark uppercase`}
            }
          }
        }
      }
    }
  }
`;
