import tw, { styled } from 'twin.macro';

export const HomeBannerStyled = styled.div`
  ${tw`text-base flex-col flex h-screen`};

  .home-banner.large {
    &:before,
    &:after {
      background-position: top center !important;
      height: 97% !important;
    }
  }

  .ves-container {
    ${tw`flex-col flex items-start justify-center h-screen`};
  }

  .banner-titles-wrapper {
    ${tw`flex-col flex justify-center flex-grow md:flex-grow-0 lg:pt-28`};
  }

  .title-wrapper {
    ${tw`mb-5 lg:pb-2 relative`};
  }

  .sub-title-wrapper {
    ${tw`mb-8 relative`};
    max-width: 600px;
  }

  h1 {
    ${tw`bg-success p-1 md:px-2 lg:px-3 text-white inline relative`};
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }

  h4 {
    ${tw`bg-primary p-1 md:px-2 lg:px-3 text-white inline relative font-normal`};
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }

  a {
    ${tw`flex w-full md:inline md:w-auto px-10  bottom-0 box-border mb-16 md:mb-0 md:mt-3`};
  }
`;
