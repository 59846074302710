import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

import Link from '../Link';

import { HomeBannerStyled } from './homeBanner.styled';

export default function HomeBanner(props) {
  const { title, subtitle, buttonLink, buttonText, banner, bannerTablet, bannerMobile } = props;
  const image = getImage(banner);
  const bgImage = convertToBgImage(image);

  return (
    <HomeBannerStyled>
      <BackgroundImage Tag="section" className="home-banner large" {...bgImage} fadeIn>
        <div className="ves-container">
          <div className="banner-titles-wrapper">
            {title && (
              <div className="title-wrapper">
                <h1 className="h1">{title}</h1>
              </div>
            )}
            {subtitle && (
              <div className="sub-title-wrapper">
                <h4 className="h4">{subtitle}</h4>
              </div>
            )}
          </div>
          {buttonLink && (
            <Link className="btn-cta-invert" variation="btn-cta-invert" to={buttonLink}>
              {buttonText}
            </Link>
          )}
        </div>
      </BackgroundImage>

      {/* {bannerTablet && (
        <BackgroundImage
          Tag="section"
          className="home-banner medium hidden md:block"
          fluid={bannerTablet}>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          {buttonLink && <Link to={buttonLink}>{buttonText}</Link>}
        </BackgroundImage>
      )}

      {bannerMobile && (
        <BackgroundImage Tag="section" className="home-banner small sm:hidden" fluid={bannerMobile}>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          {buttonLink && <Link to={buttonLink}>{buttonText}</Link>}
        </BackgroundImage>
      )} */}
    </HomeBannerStyled>
  );
}

HomeBanner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  banner: PropTypes.shape({
    base64: PropTypes.string,
    src: PropTypes.string,
  }),
  bannerTablet: PropTypes.shape({
    base64: PropTypes.string,
    src: PropTypes.string,
  }),
  bannerMobile: PropTypes.shape({
    base64: PropTypes.string,
    src: PropTypes.string,
  }),
};
