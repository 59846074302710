import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import PropTypes from 'prop-types';

import { LatestNewsWrpStyled } from './latestnews.styled';

const LatestNewsJSX = ({ data }) => {
  const allWpNewsItem = data?.allWpNewsItem?.nodes;

  return (
    <LatestNewsWrpStyled className="ves-section">
      <div className="latest-news-inner-wrp ves-container">
        <h2 className="h2">Latest News</h2>
        <div className="latest-news-item-wrp">
          {allWpNewsItem?.map((item) => (
            <div className="latest-news-item" key={item.id}>
              <Link to={item.uri} className="news-item-link">
                <div className="image-wrp">
                  <GatsbyImage
                    image={getImage(item.featuredImage?.node?.localFile)}
                    alt={item.title}
                  />
                </div>
                <div className="content-wrp">
                  <span className="date">{item.date}</span>
                  <h4 className="h4">{item.title}</h4>
                  {item.acfNews?.briefDescription && (
                    <div className="content">{item.acfNews?.briefDescription}</div>
                  )}
                  <div className="button-wrp">
                    <span className="more-news">More News</span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </LatestNewsWrpStyled>
  );
};

export default function LatestNews(props) {
  return (
    <StaticQuery
      query={graphql`
        query News {
          allWpNewsItem(limit: 2) {
            nodes {
              date(fromNow: true)
              id
              title
              uri
              acfNews {
                briefDescription
              }
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 180, height: 237, quality: 90)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <LatestNewsJSX data={data} {...props} />}
    />
  );
}

LatestNewsJSX.propTypes = {};
