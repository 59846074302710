import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const HCFWrpStyled = styled.div`
  ${tw`bg-primary pt-36 lg:pt-28 pb-80 md:pb-64 lg:pb-40 xl:pb-24 relative`}
  z-index: 1;

  &::after {
    ${tw`absolute bottom-0 right-0`}
    content: '';
    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    width: 384px;
    height: 374px;
    z-index: -1;

    @media (min-width: ${fullConfig.theme.screens.md}) {
      width: 350px;
      height: 360px;
    }

    @media (min-width: ${fullConfig.theme.screens.xl}) {
      width: 602px;
      height: 552px;
    }
  }

  .hcf-inner-wrp {
    ${tw`grid grid-cols-1 lg:grid-cols-3 lg:gap-6 relative`}

    @media (min-width: ${fullConfig.theme.screens.md}) {
      max-width: 684px;
      ${tw`mx-auto`}
    }

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      max-width: 100%;
    }

    .content-section-wrp {
      ${tw`justify-center flex flex-col text-white text-center lg:text-left`}

      .tagline {
        ${tw`uppercase font-bold mb-2`}
      }

      h2 {
        ${tw`mb-12 mx-auto lg:mx-0`}

        max-width: 545px;
      }
    }

    .form-section-wrp {
      ${tw`col-span-2`}

      .form-wrp {
        ${tw`lg:pr-6 lg:pl-20`}
      }

      .form-field-container {
        ${tw`md:grid md:grid-cols-2 md:gap-x-6`}

        .label-span {
          ${tw`text-white`}
        }
        .form-row {
          ${tw`mb-6 md:mb-5`}
        }
      }

      .submit {
        ${tw`w-full mt-12 mx-auto md:mx-0`}

        max-width: 320px;

        @media (min-width: ${fullConfig.theme.screens.md}) {
          max-width: 180px;
        }
      }
    }

    @media (min-width: 1280px) and (max-width: 1579px) {
      ${tw`grid-cols-1`}

      .form-section-wrp {
        .form-wrp {
          ${tw`px-0`}
          max-width: 900px;

          .form-field-container {
            ${tw`grid grid-cols-3 gap-x-6`}
          }
        }
      }
    }

    .form-textarea {
      ${tw`h-12`}
    }
  }
`;
